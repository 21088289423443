<script setup lang="ts">
import { trackEvent } from '@@/bits/analytics'
import { defineAsyncComponent } from '@@/bits/vue'
import OzDivider, { OzDividerColorScheme, OzDividerOrientation } from '@@/library/v4/components/OzDivider.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import OzInput, { OzInputSizePreset } from '@@/library/v4/components/OzInput.vue'
import OzPlainButton, {
  OzPlainButtonColorScheme,
  OzPlainButtonSizePreset,
} from '@@/library/v4/components/OzPlainButton.vue'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { useSurfaceFilterStore } from '@@/pinia/surface_filter'
import { useSurfacePostSearchStore } from '@@/pinia/surface_post_search'
import { debounce } from 'lodash-es'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'

const SurfaceFilterModal = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceFilterModal.vue'))
const FilterIconWithIndicator = defineAsyncComponent(
  () => import('@@/library/v4/components/FilterIconWithIndicator.vue'),
)
const SearchIconWithIndicator = defineAsyncComponent(
  () => import('@@/library/v4/components/SearchIconWithIndicator.vue'),
)

withDefaults(
  defineProps<{
    shouldAnimateOnAppear?: boolean
  }>(),
  {
    shouldAnimateOnAppear: false,
  },
)

const surfaceFilterStore = useSurfaceFilterStore()
const surfacePostSearchStore = useSurfacePostSearchStore()

const { isContainerSmallerThanTabletLandscape } = storeToRefs(useSurfaceContainerSizeStore())
const {
  backgroundLuminance,
  colorScheme,
  isMap,
  xSearchBar,
  isSearchBarInEditMode,
  hasSidePanelOutsideSurface,
  isEmbedded,
} = storeToRefs(useSurfaceStore())
const { xFilterModal, hasSelectedFilters } = storeToRefs(surfaceFilterStore)
const { searchTerm } = storeToRefs(surfacePostSearchStore)
const { isDarkMode: browserDarkMode } = storeToRefs(useDarkModeStore())

const isDarkMode = computed(() => {
  if (isContainerSmallerThanTabletLandscape.value && !isEmbedded.value) return browserDarkMode.value
  return isMap.value ? colorScheme.value === 'dark' : backgroundLuminance.value === 'dark'
})

const shouldDisplayInEditMode = computed(
  () => isSearchBarInEditMode.value || !shouldDisplayFull.value || xFilterModal.value,
)

const shouldDisplayFull = computed(() => {
  return !isContainerSmallerThanTabletLandscape.value || isEmbedded.value
})

const heightAndWidthClass = computed(() => {
  let widthClass = 'w-full'
  let heightClass = 'h-13'
  if (shouldDisplayFull.value) {
    if (shouldDisplayInEditMode.value) {
      heightClass = 'h-10'
      widthClass = 'w-64'
    } else {
      heightClass = 'h-8'

      if (hasSearchTerm.value) {
        widthClass = 'w-64'
      } else {
        widthClass = 'w-18'
      }
    }
  }

  return `${widthClass} ${heightClass}`
})
// Use a local ref that's updated on every keystroke to avoid the delay in showing
// the clear button (which is shown only when `hasSearchTerm` is true).
const localSearchTerm = ref(searchTerm.value)

const hasSearchTerm = computed(() => localSearchTerm.value.length > 0)

const clearSearchTermAndFocusInput = () => {
  localSearchTerm.value = ''
  inputRef.value?.focusInput()
}

const handleSearchInputEscKey = (): void => {
  // If there is a search term, we only want to clear it.
  // If no more search term, we want to reset.
  if (localSearchTerm.value.length > 0) {
    localSearchTerm.value = ''
    return
  }
  surfaceFilterStore.resetPostFilters()
  inputRef.value?.blurInput()
}

// We still want to delay showing the search results so we debounce the sync here.
const debouncedSyncSearchTerm = debounce(() => {
  surfacePostSearchStore.setSearchTerm(localSearchTerm.value.trim())
}, 300)

watch(localSearchTerm, debouncedSyncSearchTerm)
watch(searchTerm, (newSearchTerm) => (localSearchTerm.value = newSearchTerm.trim()))

const openFilterModal = (e: MouseEvent | KeyboardEvent) => {
  xFilterModal.value = true
  trackEvent('Surface', 'Opened filter posts panel')
}

const applySearchAndFilters = () => {
  xSearchBar.value = false
}

const searchBarRef = ref<HTMLDivElement>()

const exitEditModeIfFocusTargetIsOutside = (e: FocusEvent) => {
  // When OzInput loses focus, we don't want to set `isSearchBarInEditMode` to `false` too early
  // because it may be a Tab press to focus on the next buttons. To prevent this, we
  // check `e.relatedTarget` to see if it's an element inside the search bar or not.
  // If it's not, then we can safely set `isSearchBarInEditMode` to `false`.
  if (!searchBarRef.value) return
  if (!searchBarRef.value.contains(e.relatedTarget as Node | null)) {
    isSearchBarInEditMode.value = false
  }
}

const exitEditMode = () => {
  isSearchBarInEditMode.value = false
  inputRef?.value?.blurInput()
}

const inputRef = ref<InstanceType<typeof OzInput>>()

const selectInput = () => {
  inputRef.value?.selectInput()
}

const focusInput = () => {
  inputRef.value?.focusInput()
}
</script>

<template>
  <Transition name="slide" :appear="shouldAnimateOnAppear" @after-appear="focusInput">
    <div
      id="surface-search-bar"
      ref="searchBarRef"
      :data-mode="shouldDisplayInEditMode ? 'edit' : 'preview'"
      :class="[
        'flex items-center justify-center',
        'box-border',
        // Transition
        'transition-all motion-reduce:transition-none',
        {
          'duration-180': shouldDisplayInEditMode,
          'duration-300': !shouldDisplayInEditMode,
        },
        'ease-in-out',
        // Size
        heightAndWidthClass,
        shouldDisplayFull ? 'px-0' : 'ps-4 pe-2',
        // Background
        shouldDisplayFull ? 'bg-transparent dark:bg-transparent' : 'bg-light-ui-100 dark:bg-dark-ui-100',
        // Cursor
        {
          'cursor-text': shouldDisplayFull && shouldDisplayInEditMode,
          'search-bar-cursor-pointer': !shouldDisplayInEditMode,
        },
        hasSidePanelOutsideSurface && !shouldDisplayFull && 'absolute',
        xFilterModal && 'z-sidepanel',
      ]"
      data-pepin-id="surface-search-bar"
      data-pepin-trigger="click"
      data-testid="surfaceSearchBar"
      @click="selectInput"
      @keydown.enter="selectInput"
    >
      <OzPlainButton
        v-if="!shouldDisplayFull"
        data-testid="surfaceSearchBarFilterButton"
        class="me-4"
        :aria-label="__('Open filter posts modal')"
        :size-preset="OzPlainButtonSizePreset.H28px"
        :color-scheme="OzPlainButtonColorScheme.SecondaryIcon"
        :dark-mode="isDarkMode"
        @click="openFilterModal"
        @keydown.enter="openFilterModal"
      >
        <template #icon>
          <OzIcon v-if="!hasSelectedFilters" name="filter_outline" :size="24" />
          <FilterIconWithIndicator v-else :dark-mode="isDarkMode" color-shade="text-200" :size="24" />
        </template>
      </OzPlainButton>
      <OzInput
        ref="inputRef"
        test-id="surfaceSearchBarInput"
        dir="auto"
        :dark-mode="isDarkMode"
        :size-preset="shouldDisplayInEditMode ? OzInputSizePreset.H40px : OzInputSizePreset.H32px"
        :x-border="shouldDisplayInEditMode"
        :class="[
          shouldDisplayFull ? 'me-0' : 'me-2',
          'overflow-hidden',
          // Background
          shouldDisplayInEditMode &&
            shouldDisplayFull && {
              'bg-popover-light': !isDarkMode,
              'bg-popover-dark': isDarkMode,
            },
          !shouldDisplayInEditMode &&
            shouldDisplayFull && {
              'bg-[rgba(255,255,255,0.32)] hhover:bg-light-ui-100': !isDarkMode,
              'bg-[rgba(0,0,0,0.24)] hhover:bg-dark-ui-100': isDarkMode,
              'backdrop-blur-sm': true,
            },
          // Text
          shouldDisplayInEditMode &&
            hasSearchTerm && {
              'text-dark-text-100': !isDarkMode,
              'text-light-text-100': isDarkMode,
            },
          shouldDisplayInEditMode &&
            !hasSearchTerm && {
              'text-dark-text-400': !isDarkMode,
              'text-light-text-400': isDarkMode,
            },
          !shouldDisplayInEditMode &&
            hasSearchTerm && {
              'text-dark-text-100': !isDarkMode,
              'text-light-text-100': isDarkMode,
            },
          !shouldDisplayInEditMode &&
            !hasSearchTerm && {
              'text-dark-text-200': !isDarkMode,
              'text-light-text-200': isDarkMode,
            },
          !shouldDisplayInEditMode && {
            'hover-hover:hover:text-dark-text-100': !isDarkMode,
            'hover-hover:hover:text-light-text-100': isDarkMode,
          },
        ]"
        type="search"
        inputmode="search"
        enterkeyhint="search"
        autocomplete="off"
        maxlength="100"
        :should-truncate-text="shouldDisplayFull"
        :aria-label="__('Search posts by keywords')"
        :placeholder="shouldDisplayInEditMode && __('Search posts')"
        :model-value="localSearchTerm"
        @update:modelValue="localSearchTerm = $event"
        @focusin="isSearchBarInEditMode = true"
        @blur="exitEditModeIfFocusTargetIsOutside"
        @keydown.escape.prevent="handleSearchInputEscKey"
        @keydown.enter.stop.prevent="exitEditMode"
      >
        <template v-if="shouldDisplayFull" #startAdornment>
          <div
            :class="[
              'flex',
              'items-center',
              'justify-center',
              {
                'mx-2': shouldDisplayInEditMode || hasSearchTerm,
                'ms-3 me-2': !shouldDisplayInEditMode && !hasSearchTerm,
              },
            ]"
          >
            <OzIcon
              v-if="(!hasSearchTerm && !hasSelectedFilters) || shouldDisplayInEditMode"
              name="search"
              :size="20"
              :class="{
                'text-dark-text-200': !isDarkMode,
                'text-light-text-200': isDarkMode,
              }"
            />
            <SearchIconWithIndicator v-else :dark-mode="isDarkMode" color-shade="text-200" :size="20" />
          </div>
        </template>
        <template #endAdornment>
          <Transition
            enter-active-class="transition-all motion-reduce:transition-none transform motion-reduce:transform-none"
            enter-class="opacity-0 translate-x-full rtl:-translate-x-full"
            enter-to-class="opacity-100 translate-x-0"
            leave-active-class="transition-opacity motion-reduce:transition-none"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
            :duration="{ enter: 180, leave: 300 }"
          >
            <div v-if="shouldDisplayInEditMode" class="flex gap-2 mx-2">
              <OzPlainButton
                v-if="hasSearchTerm"
                data-testid="surfaceSearchBarClearButton"
                :aria-label="__('Clear search keywords')"
                :size-preset="OzPlainButtonSizePreset.H28px"
                :color-scheme="OzPlainButtonColorScheme.SecondaryIcon"
                :dark-mode="isDarkMode"
                @blur="exitEditModeIfFocusTargetIsOutside"
                @keydown.escape="exitEditModeIfFocusTargetIsOutside"
                @click="clearSearchTermAndFocusInput"
                @mousedown.prevent
                @keydown.enter="clearSearchTermAndFocusInput"
              >
                <template #icon>
                  <OzIcon name="clear_filled" :size="20" />
                </template>
              </OzPlainButton>
              <OzDivider
                v-if="hasSearchTerm && shouldDisplayFull"
                :dark-mode="isDarkMode"
                :color-scheme="OzDividerColorScheme.Solid"
                :orientation="OzDividerOrientation.Vertical"
                class="!w-[1px]"
              />
              <OzPlainButton
                v-if="shouldDisplayFull"
                data-testid="surfaceSearchBarFilterButton"
                :aria-label="__('Open filter posts modal')"
                :size-preset="OzPlainButtonSizePreset.H28px"
                :color-scheme="OzPlainButtonColorScheme.SecondaryIcon"
                :dark-mode="isDarkMode"
                @blur="exitEditModeIfFocusTargetIsOutside"
                @keydown.escape="exitEditModeIfFocusTargetIsOutside"
                @click="openFilterModal"
                @mousedown.prevent
                @keydown.enter="openFilterModal"
              >
                <template #icon>
                  <OzIcon
                    v-if="!hasSelectedFilters"
                    :name="xFilterModal ? 'filter_filled' : 'filter_outline'"
                    :size="20"
                  />
                  <FilterIconWithIndicator v-else :dark-mode="isDarkMode" color-shade="text-200" :size="20" />
                </template>
              </OzPlainButton>
            </div>
            <div v-else :class="shouldDisplayFull && 'pe-3'"></div>
          </Transition>
        </template>
      </OzInput>
      <OzPlainButton
        v-if="!shouldDisplayFull"
        data-testid="surfaceSearchBarDoneButton"
        :dark-mode="isDarkMode"
        :color-scheme="OzPlainButtonColorScheme.Primary"
        :aria-label="__('Apply search and filters')"
        :text="__('Done')"
        @click="applySearchAndFilters"
      />
      <SurfaceFilterModal v-if="xFilterModal" />
    </div>
  </Transition>
</template>

<style>
.search-bar-cursor-pointer * {
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
}
.slide-enter-to,
.slide-leave {
  transform: translateY(0);
}

@media (prefers-reduced-motion: reduce) {
  .slide-enter,
  .slide-leave-to {
    transform: none;
  }
  .slide-enter-to,
  .slide-leave {
    transform: none;
  }
}

.slide-enter-active {
  transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1); // https://material.io/design/motion/speed.html#easing
}
.slide-leave-active {
  transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1); // https://material.io/design/motion/speed.html#easing
}
</style>
